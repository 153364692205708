var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-6"
  }, [_c('div', {
    staticClass: "font-bold text-3xl"
  }, [_vm._v("Hello, " + _vm._s(_vm.profile.companyName))]), _c('div', {
    staticClass: "mt-10"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('p', {
    staticClass: "font-semibold mb-4 flex gap-1 items-centerpy-1"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Profile ")])]), _c('div', {
    staticClass: "bg-white p-6 flex w-11/12 rounded-lg text-base mb-6"
  }, [_vm._m(0), _c('div', {
    staticClass: "font-normal w-64"
  }, [_c('p', {
    staticClass: "leading-10 text-sm"
  }, [_vm._v(_vm._s(_vm.profile.companyName))]), _c('p', {
    staticClass: "leading-10 text-sm"
  }, [_vm._v(_vm._s(_vm.profile.picName))]), _c('p', {
    staticClass: "leading-10 text-sm"
  }, [_vm._v(_vm._s(_vm.profile.picEmail))]), _c('div', {
    staticClass: "h-10 leading-10"
  }, [!_vm.edited.isEditInfo ? _c('p', {
    staticClass: "leading-10 text-sm"
  }, [_vm._v(_vm._s(!_vm.profile.brandName ? '-' : _vm.profile.brandName))]) : _c('TextField', {
    attrs: {
      "borderEnabled": "",
      "type": "text",
      "placeholder": "Brand Name"
    },
    model: {
      value: _vm.profile.brandName,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "brandName", $$v);
      },
      expression: "profile.brandName"
    }
  })], 1)]), _c('div', {
    staticClass: "font-semibold w-48 ml-10"
  }, [_c('p', {
    staticClass: "leading-10 text-sm"
  }, [_vm._v("Phone Number")]), _c('p', {
    staticClass: "leading-10 text-sm"
  }, [_vm._v("Type")]), _c('p', {
    staticClass: "leading-10 text-sm"
  }, [_vm._v("Registration Date")]), _c('button', {
    staticClass: "bg-yellow-secondary font-bold text-sm text-yellow py-2 px-6 rounded-lg cursor-pointer mt-10",
    on: {
      "click": function click($event) {
        return _vm.editInfoAdmin(_vm.edited.isEditInfo);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.edited.isEditInfo ? 'Save' : 'Edit Info') + " ")])]), _c('div', {
    staticClass: "font-normal w-64"
  }, [_c('p', {
    staticClass: "leading-10 text-sm"
  }, [_vm._v(_vm._s(_vm.profile.phoneNumber))]), _c('p', {
    staticClass: "leading-10 text-sm capitalize"
  }, [_vm._v(" " + _vm._s(_vm.profile.roles.length == 0 ? 'Empety Role' : _vm.profile.roles.map(function (e) {
    return e.toLowerCase();
  }).join(', ').replace(/_/g, ' ')) + " ")]), _c('p', {
    staticClass: "leading-10 text-sm"
  }, [_vm._v(" " + _vm._s(_vm.moment(_vm.profile.createdDate).local().format('DD MMMM YYYY HH:mm')) + " ")])])]), _c('div', {
    staticClass: "mb-2"
  }, [_c('p', {
    staticClass: "font-semibold mb-4 flex gap-1 items-centerpy-1"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Signer Info ")])]), _c('div', {
    staticClass: "bg-white p-6 flex w-11/12 rounded-lg text-base mb-6"
  }, [_vm._m(1), _c('div', {
    staticClass: "font-normal w-64"
  }, [!_vm.edited.isEditDetai ? _c('div', {
    staticStyle: {
      "margin-top": "97px"
    }
  }, [_c('p', {
    staticClass: "leading-10 -mt-24"
  }, [_vm._v(_vm._s(!_vm.documentadmin.signerName ? '-' : _vm.documentadmin.signerName))]), _c('p', {
    staticClass: "leading-10 mt-3"
  }, [_vm._v(_vm._s(!_vm.documentadmin.signerTitle ? '-' : _vm.documentadmin.signerTitle))]), _c('p', {
    staticClass: "leading-10 mt-3"
  }, [_vm._v(_vm._s(!_vm.documentadmin.companyAddress ? '-' : _vm.documentadmin.companyAddress))])]) : _c('div', {
    staticStyle: {
      "margin-top": "-35px"
    }
  }, [_c('TextField', {
    staticClass: "leading-10 mt-9",
    attrs: {
      "borderEnabled": "",
      "type": "text",
      "placeholder": "Signer Name"
    },
    model: {
      value: _vm.documentadmin.signerName,
      callback: function callback($$v) {
        _vm.$set(_vm.documentadmin, "signerName", $$v);
      },
      expression: "documentadmin.signerName"
    }
  }), _c('TextField', {
    staticClass: "leading-10 mt-2",
    attrs: {
      "borderEnabled": "",
      "type": "text",
      "placeholder": "Signer Title"
    },
    model: {
      value: _vm.documentadmin.signerTitle,
      callback: function callback($$v) {
        _vm.$set(_vm.documentadmin, "signerTitle", $$v);
      },
      expression: "documentadmin.signerTitle"
    }
  }), _c('TextField', {
    staticClass: "leading-10 mt-2",
    attrs: {
      "borderEnabled": "",
      "type": "text",
      "placeholder": "Company Addres"
    },
    model: {
      value: _vm.documentadmin.companyAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.documentadmin, "companyAddress", $$v);
      },
      expression: "documentadmin.companyAddress"
    }
  })], 1)]), _c('div', {
    staticClass: "font-semibold w-48 ml-10"
  }, [_c('p', {
    staticClass: "leading-10 text-sm mt-1"
  }, [_vm._v("Company Logo")]), _c('button', {
    staticClass: "bg-yellow-secondary font-bold text-sm text-yellow py-2 px-6 rounded-lg cursor-pointer mt-10",
    on: {
      "click": function click($event) {
        return _vm.editDetailAdmin(_vm.edited.isEditDetai);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.edited.isEditDetai ? 'Save' : 'Edit Detail') + " ")])]), _c('div', {
    staticClass: "font-normal w-64"
  }, [_c('input', {
    ref: "photo",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "accept": "image/jpg, image/png, image/webp",
      "type": "file"
    },
    on: {
      "change": function change($event) {
        return _vm.onFileChange($event);
      }
    }
  }), _c('div', {
    staticClass: "border border-yellow-400 w-16 h-16 mt-1 rounded-lg -ml-6",
    on: {
      "click": function click($event) {
        _vm.edited.isEditDetai ? _vm.$refs.photo.click() : '';
      }
    }
  }, [_vm.documentadmin.companyLogoUrl !== '' ? _c('img', {
    class: "w-full h-full rounded-lg ".concat(_vm.edited.isEditDetai ? 'cursor-pointer' : 'cursor-default'),
    attrs: {
      "src": _vm.documentadmin.companyLogoUrl
    }
  }) : _c('p', {
    class: "text-center p-2 text-xs font-normal pt-4 ".concat(_vm.edited.isEditDetai ? 'cursor-pointer' : 'cursor-default')
  }, [_vm._v(" " + _vm._s(!_vm.edited.isEditDetai ? 'No Image' : 'Change Photo') + " ")])])])]), _c('div', {
    staticClass: "mb-2"
  }, [_c('p', {
    staticClass: "font-semibold mb-4 flex gap-1 items-center py-1"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Attachment ")])]), _c('div', {
    staticClass: "bg-white w-11/12"
  }, [_vm._m(2), _c('div', {
    staticClass: "flex flex-wrap rounded-lg text-sm mb-11 p-6"
  }, _vm._l(_vm.formDataAttachment, function (data, idx1) {
    return _c('div', {
      key: idx1,
      staticClass: "w-full lg:w-1/2 -mt-6"
    }, [_vm._l(data, function (doc, idx2) {
      return _c('div', {
        key: idx2,
        staticClass: "font-semibold w-full items-center leading-snug flex my-4"
      }, [_c('p', {
        staticClass: "w-44"
      }, [_vm._v(_vm._s(doc.name))]), !_vm.formEdit && !doc.url ? _c('p', {
        staticClass: "text-sm ml-2"
      }, [_vm._v("-")]) : _vm._e(), !_vm.formEdit && doc.url ? _c('p', {
        class: "py-2 px-2.5 font-bold text-sm text-yellow rounded-lg w-3/4  ".concat(doc.photoName ? 'cursor-pointer' : ''),
        on: {
          "click": function click($event) {
            return _vm.toPreview(doc.url, doc.photoName);
          }
        }
      }, [_vm._v(" " + _vm._s(doc.photoName ? doc.photoName : '-') + " ")]) : _vm._e(), _c('input', {
        staticClass: "cursor-pointer text-yellow-400 text-sm hidden",
        attrs: {
          "accept": "image/jpeg, image/png, application/pdf, image/webp",
          "type": "file",
          "id": "inputFIle".concat(idx1).concat(idx2)
        },
        on: {
          "change": function change($event) {
            return _vm.onFileChangeAttachment($event, doc.key);
          }
        }
      }), _vm.formEdit ? _c('button', {
        class: "".concat(doc.choosed ? 'bg-green-400 text-white' : 'bg-yellow-secondary text-yellow', " py-2 px-4 font-bold text-sm rounded-lg cursor-pointer w-1/2 truncate ..."),
        attrs: {
          "onclick": "document.getElementById('inputFIle".concat(idx1).concat(idx2, "').click()")
        }
      }, [_vm._v(" " + _vm._s(doc.url ? doc.photoName ? doc.photoName : 'Choose File' : doc.photoName ? doc.photoName : 'Change Document') + " ")]) : _vm._e()]);
    }), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: idx1 === 1,
        expression: "idx1 === 1"
      }]
    }, [!_vm.formEdit ? _c('button', {
      staticClass: "bg-yellow-secondary font-bold text-sm text-yellow py-2 px-6 rounded-lg cursor-pointer mt-24 mb-8",
      on: {
        "click": function click($event) {
          _vm.formEdit = true;
        }
      }
    }, [_vm._v("Edit Documents")]) : _c('button', {
      staticClass: "bg-yellow-secondary font-bold text-sm text-yellow py-2 px-6 rounded-lg cursor-pointer mt-24 mb-8",
      on: {
        "click": _vm.saveDocumentAttachment
      }
    }, [_vm._v("Save")])])], 2);
  }), 0)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-semibold w-48"
  }, [_c('p', {
    staticClass: "leading-10 text-sm"
  }, [_vm._v("Company Name")]), _c('p', {
    staticClass: "leading-10 text-sm"
  }, [_vm._v("PIC Name")]), _c('p', {
    staticClass: "leading-10 text-sm"
  }, [_vm._v("Email")]), _c('p', {
    staticClass: "leading-10 text-sm"
  }, [_vm._v("Brand Name")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-semibold w-48"
  }, [_c('p', {
    staticClass: "leading-10 text-sm mt-1"
  }, [_vm._v("Signer Name")]), _c('p', {
    staticClass: "leading-10 text-sm mt-3"
  }, [_vm._v("Signer Title")]), _c('p', {
    staticClass: "leading-10 text-sm mt-3"
  }, [_vm._v("Company Address")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile__form-notes",
    staticStyle: {
      "margin-top": "20px",
      "margin-left": "22px"
    }
  }, [_c('p', [_vm._v("File Format: pdf,jpg/png")]), _c('span', [_vm._v("!")])]);
}]

export { render, staticRenderFns }