import axios from 'axios'

export async function toModelingDataAttachmentForSave(attachment) {
  const doc = []
  for (let i = 0; i < attachment.length; i++) {
    if (attachment[i].partnerDocumentUrl) {
      const conditionEsited = attachment[i].partnerDocumentUrl.includes('data:image/') || attachment[i].partnerDocumentUrl.includes('data:application/')
      let url
      if (conditionEsited) {
        url = await upladImageControllerAttcachment(attachment[i].partnerDocumentUrl, attachment[i].type)
      }
      doc.push({
        comment: '',
        partnerDocumentUrl: conditionEsited ? `${url.url}/${url.key}` : attachment[i].partnerDocumentUrl,
        registrationDocumentId: attachment[i].registrationDocumentId,
        registrationDocumentName: attachment[i].registrationDocumentName,
        originalFileName: attachment[i].originalFileName ? attachment[i].originalFileName : ""
      })
    }
  }
  return doc
}
async function upladImageControllerAttcachment(url, type) {
  let baseUrl
  switch (process.env.NODE_ENV) {
    case 'production':
      baseUrl = 'https://prod-api-v4.g2academy.co'
      break
    case 'staging':
      baseUrl = 'https://staging-api-v4.g2academy.co'
      break
    case 'beta':
      baseUrl = 'https://dev-api-v4.g2academy.co'
      break
  }
  const { data } = await axios({
    method: 'GET',
    url: `${baseUrl}/uploads?contentType=${type}`
  })
  let binary = atob(url.split(',')[1])
  let array = []
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  let blobData = new Blob([new Uint8Array(array)], { type: type })
  await fetch(data.uploadUrl, {
    method: 'PUT',
    body: blobData
  })
  return { key: data.key, url: data.uploadUrl }
}
